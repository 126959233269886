import { template as template_975d11023d8345aebd58349a30ee7156 } from "@ember/template-compiler";
const WelcomeHeader = template_975d11023d8345aebd58349a30ee7156(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
