import { template as template_d11d6632272449f3996bebd90be4bc61 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d11d6632272449f3996bebd90be4bc61(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
