import { template as template_cfc19c6f6860425e93819f1b1c58de54 } from "@ember/template-compiler";
const FKText = template_cfc19c6f6860425e93819f1b1c58de54(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
